import { QueryResult } from "@apollo/client";
import { useMemo } from "react";

import {
    BookingFragment,
    BookingQuery,
    BookingQueryVariables,
    useBookingQuery,
    BookingQuestionFragment,
    BookingAvailabilityFragment,
    BookingAvailabilityPersonFragment,
    BookingAvailabilityOptionFragment,
    BookingAvailabilityProductFragment,
    BookingAvailabilityProductCancellationPolicyFragment,
    BookingAvailabilityQuestionFragment,
} from "./graphql";

export type Booking = Omit<BookingFragment, "availabilityList" | "questionList"> & {
    availabilities: BookingAvailabilityFragment[];
    bookingQuestions: BookingFragment["questionList"]["nodes"];
};
export type BookingQuestion = Omit<BookingQuestionFragment, "__typename">;

export type BookingAvailabilityQuestion = BookingAvailabilityQuestionFragment;
export type BookingAvailability = BookingAvailabilityFragment;
export type BookingAvailabilityPerson = BookingAvailabilityPersonFragment;
export type BookingAvailabilityOption = BookingAvailabilityOptionFragment;
export type BookingAvailabilityProduct = BookingAvailabilityProductFragment;
export type BookingAvailabilityProductCancellationPolicy = BookingAvailabilityProductCancellationPolicyFragment;
export type BookingAvailabilityProductCancellationPenalty =
    BookingAvailabilityProductCancellationPolicy["penaltyList"]["nodes"][number];
export type BookingInput = BookingQueryVariables["input"];

export const useBooking = (
    id: string,
    input?: BookingQueryVariables["input"]
): QueryResult<BookingQuery, { id: string; input?: BookingQueryVariables["input"] }> & {
    booking: Booking | undefined;
} => {
    const response = useBookingQuery({
        variables: { id, input },
        fetchPolicy: "cache-and-network",
        skip: !id,
    });

    const { data: currentBookingData, previousData } = response;
    const bookingData = currentBookingData?.booking ?? previousData?.booking;

    const booking = useMemo(() => {
        if (!bookingData) return undefined;
        const { availabilityList, questionList, ...rest } = bookingData;
        const availabilities = availabilityList.nodes;
        const bookingQuestions = questionList.nodes;
        return { ...rest, availabilities, bookingQuestions };
    }, [bookingData]);

    return {
        ...response,
        booking,
    };
};

export default useBooking;
