import { ApolloError } from "@apollo/client";
import noop from "lodash/noop";
import { useState, useEffect, useCallback, useMemo, useContext, createContext } from "react";
import { MarkRequired } from "ts-essentials";

import { BookingAnswerInput, BookingAvailabilityStateType } from "@holibob-packages/graphql-types";

import { BookingFormProviderParams } from "../..";
import { Booking, BookingAvailability } from "../../../apiHooks/useBooking";
import useBookingManager, { BookingManagerReturnValue } from "../../../apiHooks/useBookingManager";
import { useNextTranslation } from "../../../hooks/useNextTranslation";
import { noopAsync } from "../../../utils/noopAsync";

export type BookingFormValues = {
    reference: string | null;
    leadPassengerName: string | null;
    questions: Record<string, string | null>;
    isTermsAccepted: boolean | null;
};

export type BookingFormContextValue = Required<
    Pick<
        BookingManagerReturnValue,
        "settings" | "permissions" | "isCommitting" | "bookingDeleteAvailability" | "bookingCommit" | "bookingCancel"
    >
> & {
    booking?: Booking;
    loading: boolean;
    error?: ApolloError;
    termsAccepted: boolean;
    showPaymentModal: boolean;
    initialValues?: BookingFormValues;
    onSubmit: ((values: BookingFormValues) => void) | null;
    isComplete?: boolean;
    isExpired?: boolean;
    showConfirmModal: boolean;
    onCommit: () => Promise<void>;
    refetch: () => void;
    setTermsAccepted: (isTermsAccepted: boolean) => void;
    setShowPaymentModal: (showPaymentModal: boolean) => void;
    setShowConfirmModal: (showConfirmModal: boolean) => void;
};

const DEFAULT_SETTINGS: MarkRequired<BookingManagerReturnValue, "settings">["settings"] = {
    siteOwnerName: undefined,
    exitManageBookingUrl: undefined,
    exitManageBookingText: undefined,
    defaultCountryIsoCode: undefined,
    requireBookingExternalReference: false,
    showCancelEntireBookingButton: false,
    isConsumerFacing: false,
    isProduction: false,
    isHolibobAdmin: false,
    showBookingAvailabilityStatus: false,
};

const DEFAULT_PERMISSIONS: MarkRequired<BookingManagerReturnValue, "permissions">["permissions"] = {
    canCreate: false,
    canRemove: false,
    canUpdate: false,
    canForceCancel: false,
};

export const BookingFormContext = createContext<BookingFormContextValue>({
    booking: undefined,
    isCommitting: false,
    loading: false,
    error: undefined,
    onCommit: noopAsync,
    refetch: noopAsync,
    bookingDeleteAvailability: noopAsync,
    bookingCommit: noopAsync,
    bookingCancel: noopAsync,
    setTermsAccepted: noop,
    termsAccepted: false,
    showPaymentModal: false,
    setShowPaymentModal: noop,
    initialValues: undefined,
    onSubmit: noop,
    isComplete: false,
    isExpired: false,
    showConfirmModal: false,
    setShowConfirmModal: noop,
    settings: DEFAULT_SETTINGS,
    permissions: DEFAULT_PERMISSIONS,
});

export const PENDING_AVAILABILITY_STATES: BookingAvailabilityStateType[] = [
    BookingAvailabilityStateType.PendingAmendment,
    BookingAvailabilityStateType.PendingCancellation,
];

type BookingFormComputeInitialValuesParams = {
    booking: Booking | undefined;
    isTermsAccepted: boolean | null;
};
function useBookingFormComputeInitialValues(params: BookingFormComputeInitialValuesParams) {
    const { booking, isTermsAccepted } = params;

    return useMemo(() => {
        if (!booking) return;

        const { reference, leadPassengerName, availabilities, bookingQuestions } = booking;

        const initialValues: BookingFormValues = {
            reference,
            leadPassengerName,
            questions: {},
            isTermsAccepted: isTermsAccepted ?? false,
        };

        bookingQuestions.forEach((question) => {
            initialValues.questions[question.id] = question.answerValue;
        });

        availabilities.forEach((availability) => {
            const { personList, questionList } = availability;
            const { nodes: questions } = questionList;
            const { nodes: persons } = personList;

            questions.forEach((question) => {
                initialValues.questions[question.id] = question.answerValue;
            });

            persons.forEach((person) => {
                person.questionList.nodes.forEach((question) => {
                    initialValues.questions[question.id] = question.answerValue;
                });
            });
        });

        return initialValues;
    }, [booking, isTermsAccepted]);
}

function useCreateBookingFormOnSubmit(
    booking: Booking | undefined,
    setInput: BookingManagerReturnValue["setInput"],
    setDidSubmit: (didSubmit: boolean) => void
) {
    const onSubmit = useCallback(
        (values: BookingFormValues) => {
            const state = booking?.state;

            if (state !== "OPEN") setInput(null);
            else {
                const { leadPassengerName, reference, questions } = values;
                const answerList: BookingAnswerInput[] = [];

                Object.entries(questions).forEach(([questionId, value]) => {
                    if (value) {
                        answerList.push({ questionId, value });
                    }
                });

                const input = {
                    leadPassengerName,
                    reference,
                    answerList,
                };

                setInput(input);
            }

            setDidSubmit(true);
        },
        [booking, setInput, setDidSubmit]
    );

    return booking ? onSubmit : null;
}

type UseBookingFormPaymentPendingParams = {
    booking?: Booking;
    didSubmit: boolean;
    showPaymentModal: boolean;
    setShowPaymentModal: BookingFormContextValue["setShowPaymentModal"];
    setDidSubmit: (didSubmit: boolean) => void;
};

function useBookingFormPaymentPending({
    booking,
    didSubmit,
    showPaymentModal,
    setShowPaymentModal,
    setDidSubmit,
}: UseBookingFormPaymentPendingParams) {
    const state = booking?.state;
    const canCommit = booking?.canCommit;

    const paymentPending = useMemo(() => {
        return state && didSubmit && canCommit && ["OPEN", "PAYMENT"].includes(state) && !showPaymentModal;
    }, [didSubmit, canCommit, state, showPaymentModal]);

    useEffect(() => {
        if (!paymentPending) return;

        setShowPaymentModal(true);
        setDidSubmit(false);
    }, [paymentPending, setShowPaymentModal, setDidSubmit]);
}

export function useBookingFormBaseContextCreate(
    bookingManagerData: BookingManagerReturnValue
): BookingFormContextValue {
    const {
        setInput,
        refetch,
        booking,
        isCommitting,
        bookingDeleteAvailability,
        bookingCommit,
        bookingCancel,
        loading,
        error,
        settings,
        permissions,
    } = bookingManagerData;
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const state = booking?.state;

    const onSubmit = useCreateBookingFormOnSubmit(booking, setInput, setDidSubmit);

    useBookingFormPaymentPending({
        booking,
        didSubmit,
        showPaymentModal,
        setShowPaymentModal,
        setDidSubmit,
    });

    const onCommit = useCallback(async () => {
        setShowPaymentModal(false);
        await bookingCommit();
        setShowConfirmModal(true);
    }, [bookingCommit, setShowConfirmModal, setShowPaymentModal]);

    const initialValues = useBookingFormComputeInitialValues({ booking, isTermsAccepted: termsAccepted });
    const isComplete = state && state !== "OPEN" && state !== "PAYMENT";
    const isExpired = state && state === "EXPIRED";

    return useMemo(() => {
        const context: BookingFormContextValue = {
            booking,
            isCommitting,
            loading,
            error,
            onCommit,
            refetch,
            bookingDeleteAvailability,
            bookingCommit,
            bookingCancel,
            setTermsAccepted,
            termsAccepted,
            showPaymentModal,
            setShowPaymentModal,
            initialValues,
            onSubmit,
            isComplete,
            isExpired,
            showConfirmModal,
            setShowConfirmModal,
            settings: settings ?? DEFAULT_SETTINGS,
            permissions: permissions ?? DEFAULT_PERMISSIONS,
        };

        return context;
    }, [
        booking,
        isCommitting,
        loading,
        error,
        onCommit,
        refetch,
        bookingDeleteAvailability,
        bookingCommit,
        bookingCancel,
        termsAccepted,
        showPaymentModal,
        initialValues,
        onSubmit,
        isComplete,
        isExpired,
        showConfirmModal,
        settings,
        permissions,
    ]);
}

export function useBookingFormContextCreate(params: BookingFormProviderParams) {
    const bookingManagerData = useBookingManager(params);
    return useBookingFormBaseContextCreate(bookingManagerData);
}

export function useBookingFormContext() {
    return useContext(BookingFormContext);
}

export function useBookingFormBooking() {
    const { booking } = useBookingFormContext();
    return booking;
}

export function useBookingFormLoading() {
    const { loading } = useBookingFormContext();
    return loading;
}

export function useBookingFormIsCommitting() {
    const { isCommitting } = useBookingFormContext();
    return isCommitting;
}

export function useBookingFormTermsAccepted() {
    const { termsAccepted, setTermsAccepted } = useBookingFormContext();
    return [termsAccepted, setTermsAccepted] as const;
}

export function useBookingFormShowPaymentModal() {
    const { showPaymentModal, setShowPaymentModal } = useBookingFormContext();
    return [showPaymentModal, setShowPaymentModal] as const;
}

export function useBookingFormShowConfirmModal() {
    const { showConfirmModal, setShowConfirmModal } = useBookingFormContext();
    return [showConfirmModal, setShowConfirmModal] as const;
}

export function useBookingFormOnPaymentSuccess(onPaymentSuccess?: () => void) {
    const { onCommit } = useBookingFormContext();

    return useCallback(async () => {
        await onCommit();
        if (onPaymentSuccess) onPaymentSuccess();
    }, [onCommit, onPaymentSuccess]);
}

export function useBookingFormInitialValues() {
    const { initialValues } = useBookingFormContext();
    const [initialFormValues, setInitialFormValues] = useState<typeof initialValues>(initialValues);

    if (initialValues && !initialFormValues) {
        setInitialFormValues(initialValues);
        return initialFormValues;
    }

    return initialFormValues;
}

export function useBookingAvailabilities() {
    const { booking } = useBookingFormContext();
    const availabilities = booking?.availabilities ?? [];
    return availabilities;
}

export function useBookingVoucherUrl() {
    const { booking } = useBookingFormContext();
    const voucherUrl = booking?.voucherUrl;
    return voucherUrl;
}

export function useBookingFormBookingState() {
    const { booking } = useBookingFormContext();
    const state = booking?.state;
    return state ?? null;
}

export function useBookingFormOnSubmit() {
    const { onSubmit } = useBookingFormContext();
    return onSubmit;
}

export function useBookingFormBookingIsComplete() {
    const { isComplete } = useBookingFormContext();
    return isComplete;
}

export function useBookingFormBookingIsPendingCommit() {
    const { booking } = useBookingFormContext();
    return !!booking?.isPendingCommit;
}

export function useBookingFormHasPendingAvailability() {
    const { booking } = useBookingFormContext();

    return booking?.availabilities.some((bk) => PENDING_AVAILABILITY_STATES.includes(bk.state));
}

export function useBookingFormStickyBottomCardIsVisible() {
    const isBookingCommitting = useBookingFormIsCommitting();
    const hasUnsavedChanges = useBookingFormBookingHasUnsavedChanges();

    return isBookingCommitting || hasUnsavedChanges;
}

export function useBookingFormBookingHasUnsavedChanges() {
    const { booking } = useBookingFormContext();

    const bookingAvailabilities = booking?.availabilities ?? [];

    const pendingAvailabilities = bookingAvailabilities.some((bk) => PENDING_AVAILABILITY_STATES.includes(bk.state));
    const openAvailabilities = bookingAvailabilities.filter((availability) => availability.state === "OPEN");

    const areAllOpened = openAvailabilities.length === bookingAvailabilities.length;

    return !!pendingAvailabilities || (openAvailabilities.length > 0 && !areAllOpened);
}

export function useBookingFormBookingTotalGross() {
    const { booking } = useBookingFormContext();

    const bookingAvailabilities = booking?.availabilities ?? [];

    return bookingAvailabilities
        .filter(
            (x) =>
                x.state !== BookingAvailabilityStateType.Amended && x.state !== BookingAvailabilityStateType.Cancelled
        )
        .reduce((acc, x) => acc + x.totalPrice.gross, 0);
}

export function useBookingOutstandingPriceSummaryLabel(price: number) {
    const [t] = useNextTranslation("booking");
    return t(price > 0 ? "label.outstandingCost" : "label.refundAmount");
}

export function useBookingFormBookingIsExpired() {
    const { isExpired } = useBookingFormContext();
    return isExpired;
}

export function useBookingFormError() {
    const { error } = useBookingFormContext();
    return error;
}

export function useBookingFormBookingQuestions() {
    const { booking } = useBookingFormContext();
    const bookingQuestions = booking?.bookingQuestions ?? [];
    return bookingQuestions;
}
export function useBookingFormIsReadOnly() {
    const booking = useBookingFormBooking();
    const isPendingCommit = useBookingFormBookingIsPendingCommit();
    const isCommitting = useBookingFormIsCommitting();
    const state = useBookingFormBookingState();

    if (isCommitting) {
        return true;
    }

    if (!booking?.useLifecycleManager) {
        return state !== "OPEN";
    }

    return !isPendingCommit;
}

export function useBookingFormDeleteAvailability() {
    const { bookingDeleteAvailability } = useBookingFormContext();
    return bookingDeleteAvailability;
}

export function useBookingFormCancelBooking() {
    const { bookingCancel } = useBookingFormContext();
    return bookingCancel;
}

export function useBookingFormBookingCanCancel() {
    const booking = useBookingFormBooking();

    return booking?.canCancel;
}

export function useBookingCancellationEffectiveRefundAmount() {
    const booking = useBookingFormBooking();

    return booking?.cancellationEffectiveRefundAmount;
}

export function useBookingFormSettings() {
    const { settings } = useContext(BookingFormContext);
    return settings;
}

export function useBookingFormPermissions() {
    const { permissions } = useContext(BookingFormContext);
    return permissions;
}

export type Penalty = {
    id: string;
    cancellationDescription: string | null | undefined;
    productName: string | null;
};

export function useBookingAvailabilityCancelationPenalties(): Penalty[] {
    const [t] = useNextTranslation("product");
    const noCancellationPolicyLabel = t("description.cancellationPolicy");
    const availabilities = useBookingAvailabilities();

    return useMemo(() => {
        return availabilities.map((availability) => {
            const { id, cancellationState, product } = availability;
            const productName = product.name;

            let cancellationDescription;
            if (cancellationState === null) {
                cancellationDescription = noCancellationPolicyLabel;
            }
            if (cancellationState) {
                const effectivePenalty = cancellationState.effectivePenalty;
                cancellationDescription = effectivePenalty.formattedText;
            }

            return { id, cancellationDescription, productName };
        });
    }, [availabilities, noCancellationPolicyLabel]);
}

export function useAvailabilityRequiresCommitting(availability: BookingAvailability) {
    const bookingHasUnsavedChanges = useBookingFormBookingHasUnsavedChanges();

    const hasBeenAmended = !!(
        availability.state === BookingAvailabilityStateType.Open &&
        availability.originBookingAvailability &&
        availability.originBookingAvailability.state === BookingAvailabilityStateType.PendingAmendment
    );

    return (
        hasBeenAmended ||
        availability.state === BookingAvailabilityStateType.PendingCancellation ||
        (bookingHasUnsavedChanges && availability.state === BookingAvailabilityStateType.Open)
    );
}
